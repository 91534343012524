import React, { useState } from 'react';
import MaintenanceCustom from '@views/Maintenance/MaintenanceCustom'
import { toast } from 'react-toastify'
import Helpers from '@components/Helpers/Helpers';
import Controllers from '@components/Controllers/Controllers';
import { SwitchToggle, Input, Radio, FormCustom } from '@components/Form/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

/**
 * Product
 * @version 0.10.5
 */
const Product = _ => {
    const [modalShow, setModalShow] = useState(false)
    const [formMass, setFormMass] = useState({
        p_file_excel: ''
    })
    const [detailsMass, setDetailsMass] = useState({})
    const [loadSubmit, setLoadSubmit] = useState(false)

    /**
     * Variables y funciones de precios
     */
    const [modalPricesShow, setModalPricesShow] = useState(false)
    const [formMassPrices, setFormMassPrices] = useState({
        p_file_excel: ''
    })
    const [detailsMassPrices, setDetailsMassPrices] = useState({})
    const [loadSubmitPrices, setLoadSubmitPrices] = useState(false)

    const [modalWebShow, setModalWebShow] = useState(false)
    const [currentWebInfo, setCurrentWebInfo] = useState({})


    return <>
        <MaintenanceCustom
            prependCard={({ setReload }) => <>

                <Modal show={modalWebShow} size="lg" onHide={_ => setModalWebShow(false)} backdrop="static">
                    <Modal.Header>
                        <Modal.Title as="div">{currentWebInfo.id} {currentWebInfo.name}</Modal.Title>
                        <div className="text-right align-self-center">
                            <svg className="cur-pointer" onClick={_ => setModalWebShow(false)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
                            </svg>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row'>
                            {currentWebInfo.media_gallery_entries?.map(itemGallery => {
                                return <div className='col-6'><img src={'https://www.outdoorretail.mx/media/catalog/product' + itemGallery.file} className='img-fluid' /></div>
                            })}
                        </div>

                        <h1>Información en E Commerce:</h1>

                        <p className='h3'><strong>Precio</strong> {currentWebInfo.price} <strong>ItemGlobal</strong> {currentWebInfo.sku}</p>


                        {currentWebInfo.custom_attributes?.map(attr => {

                            if (attr.attribute_code === "short_description") {
                                let textArea = document.createElement("textarea");
                                textArea.innerHTML = attr.value;
                                return <><h2>Descripción Corta</h2> <div dangerouslySetInnerHTML={{ __html: textArea.value }} /></>
                            }

                            if (attr.attribute_code === "description") {
                                let textArea = document.createElement("textarea");
                                textArea.innerHTML = attr.value;
                                return <><h2>Descripción Completa</h2> <div dangerouslySetInnerHTML={{ __html: textArea.value }} /></>
                            }

                            if (attr.attribute_code === "meta_keyword") {
                                return <><h3>Keywords</h3> <div/>{attr.value}</>
                            }

                        })}

                    </Modal.Body>
                </Modal>
                {/* Modal Precios */}

                <Button variant="primary" onClick={_ => {
                    setDetailsMassPrices({})
                    setModalPricesShow(true)
                }} className="mr-3 mb-2">
                    <i className="fas fa-cloud-upload-alt mr-2"></i> Carga Precios
                </Button>
                <Modal show={modalPricesShow} size="lg" onHide={_ => setModalPricesShow(false)} backdrop="static">
                    <Modal.Header>
                        <Modal.Title as="div">Carga de Precios</Modal.Title>
                        <div className="text-right align-self-center">
                            <svg className="cur-pointer" onClick={_ => setModalPricesShow(false)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
                            </svg>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <strong>Descargar Plantilla
                            <a href={Helpers.config.resourcesUrl({
                                url: '/excel/precios.xlsx'
                            })} download="PLANTILLA_CARGA_PRODUCTOS" className="ml-2">
                                <Button size="xs" variant="success"><i className="fa fa-file-excel"></i></Button>
                            </a>
                        </strong>
                        <FormCustom loadSubmitPrices={loadSubmitPrices} onSubmit={_ => {
                            setDetailsMassPrices({})
                            setLoadSubmitPrices(true)
                            Controllers.product.massPrices(Helpers.formData.generate({
                                data: formMassPrices,
                                file: [
                                    { name: 'p_file_excel', value: formMassPrices.p_file_excel[0] }
                                ]
                            })).then(res => {
                                setDetailsMassPrices(res)
                            }).catch(req => {
                                Helpers.promise.catch({ req, toast })
                            }).finally(_ => {
                                setLoadSubmitPrices(false)
                                setReload(true)
                            })
                        }}>
                            <Input
                                type="file"
                                text="Archivo Excel"
                                classNameParent="col-12 mb-2"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                required
                                invalid="Campo obligatorio"
                                onChange={e => {
                                    setFormMassPrices({
                                        p_file_excel: e.currentTarget.files.length > 0 ? e.currentTarget.files : ''
                                    })
                                }}
                            />
                        </FormCustom>
                        {detailsMassPrices?.message ? <>
                            <div className="d-flex flex-column mt-3">
                                <div><strong>{detailsMassPrices.message}</strong></div>
                                <div className="text-success">Productos registrados: {detailsMassPrices.data.qty_registerd}</div>
                                <div className="text-success">Productos actualizados: {detailsMassPrices.data.qty_updated}</div>
                                {detailsMassPrices?.data?.errors?.length > 0 ? <>
                                    <div className="text-danger font-weight-bold">Productos por corregir:</div>
                                    <div className="d-flex flex-column">
                                        {React.Children.toArray(detailsMassPrices?.data.errors?.map(r => {
                                            return <div className="text-danger">
                                                <strong>Línea #{r.line}</strong> - {r.message}
                                            </div>
                                        }))}
                                    </div>
                                </> : ''}
                            </div>
                        </> : ''}
                    </Modal.Body>
                </Modal>


                <Button variant="success" onClick={_ => {
                    setDetailsMass({})
                    setModalShow(true)
                }} className="mr-3 mb-2">
                    <i className="fas fa-cloud-upload-alt mr-2"></i> Carga Productos
                </Button>
                <Modal show={modalShow} size="lg" onHide={_ => setModalShow(false)} backdrop="static">
                    <Modal.Header>
                        <Modal.Title as="div">Carga de Productos</Modal.Title>
                        <div className="text-right align-self-center">
                            <svg className="cur-pointer" onClick={_ => setModalShow(false)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
                            </svg>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Seleccione "Descargar Productos XLSX" edite el documento descargado y vuelva a subir el archivo excel</p>
                        <FormCustom loadSubmit={loadSubmit} onSubmit={_ => {
                            setDetailsMass({})
                            setLoadSubmit(true)
                            Controllers.product.product_insert_update_mass(Helpers.formData.generate({
                                data: formMass,
                                file: [
                                    { name: 'p_file_excel', value: formMass.p_file_excel[0] }
                                ]
                            })).then(res => {
                                setDetailsMass(res)
                            }).catch(req => {
                                Helpers.promise.catch({ req, toast })
                            }).finally(_ => {
                                setLoadSubmit(false)
                                setReload(true)
                            })
                        }}>
                            <Input
                                type="file"
                                text="Archivo Excel"
                                classNameParent="col-12 mb-2"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                required
                                invalid="Campo obligatorio"
                                onChange={e => {
                                    setFormMass({
                                        p_file_excel: e.currentTarget.files.length > 0 ? e.currentTarget.files : ''
                                    })
                                }}
                            />
                        </FormCustom>
                        {detailsMass?.message ? <>
                            <div className="d-flex flex-column mt-3">
                                <div><strong>{detailsMass.message}</strong></div>
                                <div className="text-success">Productos registrados: {detailsMass.data.qty_registerd}</div>
                                <div className="text-success">Productos actualizados: {detailsMass.data.qty_updated}</div>
                                {detailsMass?.data?.errors?.length > 0 ? <>
                                    <div className="text-danger font-weight-bold">Productos por corregir:</div>
                                    <div className="d-flex flex-column">
                                        {React.Children.toArray(detailsMass?.data.errors?.map(r => {
                                            return <div className="text-danger">
                                                <strong>Línea #{r.line}</strong> - {r.message}
                                            </div>
                                        }))}
                                    </div>
                                </> : ''}
                            </div>
                        </> : ''}
                    </Modal.Body>
                </Modal>

                <a href={Helpers.config.resourcesUrl({
                    url: '/products/download?jwt=' + Helpers.localStorage.getJWT()
                })} download="Descarga de productos" className="btn btn-warning mr-3 mb-2"><i className="fas fa-cloud-download-alt mr-2"></i> Descargar Productos XLSX
                </a>
            </>}
            title={<>
                <i className="fa fa-list text-secondary mr-2"></i>Listado de Productos
            </>}
            namePlural="Productos" modalSize="xl" nameSingle="Producto" separator="el" module="product"
            removeEnabled
            removeId
            removeDescription
            tableFieldsAdd={[
                {
                    name: 'id_product',
                    align: 'center',
                    text: 'ID',
                    type: 'input',
                    hideOnForm: true,
                    options: {
                        size: '',
                        icon: '',
                        smallColor: 'font-weight-bold text-dark',

                    }
                },
                {
                    name: 'description_product', align: 'left', text: 'Descripción', type: 'input', options: {
                        required: true,
                        classNameParent: 'col-md-12',
                        size: '',
                        icon: '',
                        smallColor: 'font-weight-bold text-dark',
                    },
                    render: r => r.data_website.length > 0 ? <a onClick={_ => { setCurrentWebInfo(JSON.parse(r.data_website)); setModalWebShow(true) }} className='text-info'><i className='fa fa-cloud text-info'></i> {r.description_product}</a> : <a><i className='fa fa-exclamation-circle'></i> {r.description_product}</a>
                },
                {
                    name: 'id_item_global_product', align: 'center', text: 'Item Global', type: 'input', options: {
                        required: true,
                        classNameParent: 'col-md-6 mb-2',
                        maxLength: 80,
                        size: '',
                        icon: '',
                        smallColor: 'font-weight-bold text-dark'
                    }
                },
                {
                    name: 'key_product', align: 'center', text: 'SKU', type: 'input', options: {
                        maxLength: 255,
                        required: true,
                        classNameParent: 'col-md-6 mb-2',
                        icon: '',
                        size: '',
                        smallColor: 'font-weight-bold text-dark'
                    }
                },
                {
                    name: 'upc_product', align: 'center', text: 'UPC', type: 'input', options: {
                        required: true,
                        classNameParent: 'col-md-6 mb-2',
                        maxLength: 80,
                        size: '',
                        icon: '',
                        smallColor: 'font-weight-bold text-dark'
                    },
                    hideOnTable: false
                },
                {
                    name: 'price_product', align: 'center', text: 'Precio', type: 'input',
                    options: {
                        classNameParent: 'col-md-6 mb-2',
                        size: '',
                        icon: '',
                        type: 'number',
                        min: '0',
                        step: '0.01',
                        smallColor: 'font-weight-bold text-dark'
                    },
                    render: r => r.price_product ? <div className="text-primary"><strong>$ {Helpers.number.withCommas({ number: parseFloat(r.price_product)?.toFixed(2) })}</strong></div> : ''
                },
                {
                    name: 'id_category', nameSelect: 'category', text: 'Categoría', type: 'select', align: 'center',
                    options: {
                        classNameParent: 'col-md-6 mb-2',
                        size: '',
                        icon: '',
                        smallColor: 'font-weight-bold text-dark',
                        required: true,
                    },
                    hideOnTable: true
                },
                {
                    name: 'provider_product', align: 'center', text: 'Proveedor', type: 'input', options: {
                        required: true,
                        classNameParent: 'col-md-6 mb-2',
                        maxLength: 255,
                        size: '',
                        icon: '',
                        smallColor: 'font-weight-bold text-dark'
                    },
                    hideOnTable: false
                },
                {
                    name: 'weight_product', text: 'Peso (kg)', type: 'input', options: {
                        classNameParent: 'col-md-3 col-6 mb-2',
                        maxLength: 80,
                        size: '',
                        icon: '',
                        type: 'number',
                        min: '0',
                        step: '0.00001',
                        smallColor: 'font-weight-bold text-dark'
                    },
                    hideOnTable: true
                },
                {
                    name: 'long_product', text: 'Longitud (cm)', type: 'input', options: {
                        classNameParent: 'col-md-3 col-6 mb-2',
                        maxLength: 80,
                        size: '',
                        icon: '',
                        type: 'number',
                        min: '0',
                        step: '0.00001',
                        smallColor: 'font-weight-bold text-dark'
                    },
                    hideOnTable: true
                },
                {
                    name: 'width_product', text: 'Ancho (cm)', type: 'input', options: {
                        classNameParent: 'col-md-3 col-6 mb-2',
                        maxLength: 80,
                        size: '',
                        icon: '',
                        type: 'number',
                        min: '0',
                        step: '0.00001',
                        smallColor: 'font-weight-bold text-dark'
                    },
                    hideOnTable: true
                },
                {
                    name: 'height_product', text: 'Alto (cm)', type: 'input', options: {
                        classNameParent: 'col-md-3 col-6 mb-2',
                        maxLength: 80,
                        size: '',
                        icon: '',
                        type: 'number',
                        min: '0',
                        step: '0.00001',
                        smallColor: 'font-weight-bold text-dark'
                    },
                    hideOnTable: true
                },
            ]}
            optionsView={{
                removeEnabled: true,
                removeDescription: true,
                beforeRenderForm: ({ ready, setState }) => {
                    Promise.all([
                        Controllers.category.get_category(),
                        Controllers.unit_measure.get_unit_measure(),
                        Controllers.country.get_country()
                    ]).then(res => {
                        setState({
                            category: res[0].data,
                            unit_measure: res[1].data,
                            country: res[2].data
                        })
                        ready()
                    })
                },
                dataStart: [// TODO: Eliminar los que ya no se utilizan
                    { name: 'key_product', value: '' },
                    { name: 'id_item_global_product', value: '' },
                    { name: 'upc_product', value: '' },
                    { name: 'lang_product', value: '' },
                    { name: 'id_category', value: '' },
                    { name: 'id_unit_measure', value: '' },
                    { name: 'brand_product', value: 'THULE' },
                    { name: 'provider_product', value: '' },
                    { name: 'weight_product', value: '' },
                    { name: 'weight_um', value: '' },
                    { name: 'long_product', value: '' },
                    { name: 'long_um', value: '' },
                    { name: 'width_product', value: '' },
                    { name: 'width_um', value: '' },
                    { name: 'height_product', value: '' },
                    { name: 'height_um', value: '' },
                    { name: 'id_country', value: '' },
                    { name: 'type_tax_product', value: 'IVA' },
                    { name: 'type_rate_fee_product', value: 'ESTANDAR' },
                    { name: 'state_product', value: '' },
                    { name: 'id_planning_area_product', value: 'SEDE MX' },
                    { name: 'availability_product', value: 'STOCK' },
                    { name: 'organization_sale_product', value: 'VENTAS' },
                    { name: 'distribution_channel_product', value: 'VENTAS DIRECTAS' },
                    { name: 'position_group_product', value: 'POR ENTREGA' },
                    { name: 'business_product', value: '1000' },
                    { name: 'photo_product', value: '' },
                    { name: 'discount_type_product', value: 'SIN_DESCUENTO' },
                    { name: 'discount_price_product', value: '' },
                    { name: 'discount_percentage_product', value: '' },
                    { name: 'discount_dates_product', value: 0 },
                    { name: 'discount_start_product', value: '' },
                    { name: 'discount_end_product', value: '' },
                    { name: 'enabled_product', value: 1 },
                ],
                submitCustom: true,
                submitIsCorrect: ({ data, stateDynamic }) => {
                    if (!data.p_id_category) {
                        Helpers.toast.warning({ message: 'Seleccionar Categoría', toast })
                        return false
                    }

                    data.p_qty_product = !isNaN(parseInt(data.p_qty_product)) ? parseInt(data.p_qty_product) : ''
                    return Helpers.formData.generate({
                        data,
                        file: [
                            { name: 'p_photo_product', value: stateDynamic?.photo_product?.length > 0 ? stateDynamic.photo_product[0] : data.p_photo_product }
                        ]
                    })
                },
                append: ({ row, setRow }) => {
                    return <>
                        <div className="col-12 mb-2">
                            <hr />
                            <strong>Descuentos</strong>
                        </div>
                        <SwitchToggle
                            id="switch-enabled-discount"
                            textUnChecked="Activar Descuento"
                            textCheck="Desactivar Descuento"
                            checked={row.p_discount_type_product !== 'SIN_DESCUENTO'}
                            onChange={e => {
                                setRow({
                                    ...row,
                                    p_discount_type_product: e.currentTarget.checked ? '' : 'SIN_DESCUENTO',
                                    p_discount_price_product: '',
                                    p_discount_percentage_product: '',
                                    p_discount_dates_product: 0,
                                    p_discount_start_product: '',
                                    p_discount_end_product: ''
                                })
                            }}
                            classNameParent="col-12 mb-2"
                        />
                        {row.p_discount_type_product !== 'SIN_DESCUENTO' ? <>
                            <div className="col-12 mb-1">
                                <small className="font-weight-bold text-dark">Tipo de Porcentaje</small>
                            </div>
                            <div className="col-12 d-flex">
                                <Radio
                                    classNameParent="mr-2 mb-2"
                                    id="rdb-discount-price"
                                    text="Por Precio"
                                    name="rdb-discount-type"
                                    checked={row.p_discount_type_product === 'PRICE'}
                                    onChange={e => {
                                        setRow({
                                            ...row,
                                            p_discount_type_product: e.currentTarget.checked ? 'PRICE' : '',
                                            p_discount_price_product: '',
                                            p_discount_percentage_product: '',
                                        })
                                    }}
                                    required={row.p_discount_type_product === ''}
                                />
                                <Radio
                                    classNameParent="mb-2"
                                    id="rdb-discount-percentage"
                                    text="Por Porcentaje"
                                    name="rdb-discount-type"
                                    checked={row.p_discount_type_product === 'PERCENTAGE'}
                                    onChange={e => {
                                        setRow({
                                            ...row,
                                            p_discount_type_product: e.currentTarget.checked ? 'PERCENTAGE' : '',
                                            p_discount_price_product: '',
                                            p_discount_percentage_product: '',
                                        })
                                    }}
                                    required={row.p_discount_type_product === ''}
                                />
                            </div>
                            {row.p_discount_type_product === 'PRICE' ? <Input
                                classNameParent="col-md-4 mb-2"
                                text="Precio Descuento"
                                smallColor="font-weight-bold text-dark"
                                placeholder="0.00"
                                type="number"
                                min="0.00"
                                step="0.01"
                                value={row.p_discount_price_product}
                                onChange={e => setRow({ ...row, p_discount_price_product: e.currentTarget.value })}
                                required
                            /> : (row.p_discount_type_product === 'PERCENTAGE' ? <Input
                                classNameParent="col-md-4 mb-2"
                                text="Porcentaje Descuento"
                                smallColor="font-weight-bold text-dark"
                                placeholder="0.00"
                                type="number"
                                min="0.00"
                                step="0.01"
                                value={row.p_discount_percentage_product}
                                onChange={e => setRow({ ...row, p_discount_percentage_product: e.currentTarget.value })}
                                required
                            /> : '')}
                            <SwitchToggle
                                id="switch-enabled-dates"
                                textUnChecked="Activar Intervalo de Fechas"
                                textCheck="Desactivar Intervalo de Fechas"
                                checked={parseInt(row.p_discount_dates_product) === 1}
                                onChange={e => {
                                    setRow({
                                        ...row,
                                        p_discount_dates_product: e.currentTarget.checked ? 1 : 0,
                                        p_discount_start_product: '',
                                        p_discount_end_product: '',
                                    })
                                }}
                                classNameParent="col-12 mb-2"
                            />
                            {row.p_discount_dates_product ? <>
                                <Input
                                    classNameParent="col-md-4 mb-2"
                                    text="Fecha de Inicio"
                                    smallColor="font-weight-bold text-dark"
                                    type="date"
                                    value={row.p_discount_start_product}
                                    onChange={e => setRow({ ...row, p_discount_start_product: e.currentTarget.value })}
                                    required
                                />
                                <Input
                                    classNameParent="col-md-4 mb-2"
                                    text="Fecha de Fin"
                                    smallColor="font-weight-bold text-dark"
                                    type="date"
                                    value={row.p_discount_end_product}
                                    onChange={e => setRow({ ...row, p_discount_end_product: e.currentTarget.value })}
                                    required
                                />
                            </> : ''}
                        </> : ''}
                    </>
                },
            }}
        />
    </>
}

export default Product;